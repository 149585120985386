@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap');
/* font-family: 'Caveat', cursive; */

/* ====================
         GLOBAL
==================== */

*,
*::after,
*::before {
    box-sizing: border-box;
}

html,
body {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #464852;
    margin: 0;
    padding: 0;
    /* overflow-x: hidden; */
}

ul,
li,
ol {
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
}

/* a {
    text-decoration: none;
    color: inherit;
    font-family: inherit;
    outline: 0;
} */

button {
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    color: inherit;
    font-family: inherit;
    outline: 0;
}

button.ant-btn {
    border-radius: 4px;
}

input {
    outline: 0;
}

ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

.container {
    width: 100%;
    max-width: 1420px;
    margin: 0 auto;
    padding: 0 20px;
}

input,
textarea,
h1,
h2,
h3,
h4,
h5 {
    font-family: inherit;
    color: inherit;
}

.ant-tooltip {
    z-index: 1;
}

.ant-modal-wrap .ant-input-number {
    width: 100%;
}

.printOrder {
    padding: 15px;
}

.printOrder p {
    font-weight: 700;
}

.printOrder-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.printOrder-row p {
    font-size: 26px;
}

.printOrder span {
    font-size: 26px;
    font-weight: 700;
    opacity: 1;
}

input::placeholder,
textarea::placeholder {
    font-family: inherit;
    font-weight: 300;
}

@media (max-width: 380px) {
    .container {
        padding: 0 10px;
    }
}
